import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./shipping-info.css"

import cratedTrain from "../images/crated-train.jpg"

const ShippingInfoPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container" style={{ flex: 1 }}>
      <section className="shipping-info-page">
        <h1>Shipping Info</h1>
        <figure>
          <img src={cratedTrain} alt="crated train car" />
          <figcaption>A Creative Train Company crate</figcaption>
        </figure>
        <p>
          All Creative Train Company cars are shipped by freight, and we&apos;re
          proud to announce that we&apos;re able to ship our train cars anywhere
          within Canada and the continental United States.
        </p>
        <p>
          Shipping cost for train cars varies greatly from order to order
          because of the weight of the train car being shipped and the
          destination. Residential delivery is not currently possible; instead,
          train cars will be conveniently sent to the shipping depot nearest to
          you for pickup. Crating costs are factored into the shipping fee, so
          there&apos;s no hidden costs.
        </p>
        <p>
          <a href="/contact">Inquire today</a> for more personalized pricing
          information!
        </p>
      </section>
    </div>
  </Layout>
)

export default ShippingInfoPage
